// Fonts
@import url(https://fonts.googleapis.com/earlyaccess/notosansjp.css);

@import "vue-select/src/scss/vue-select.scss";

// Variables
@import "mixins";
@import "variables";

// Defaults
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td {
    margin: 0;
    padding: 0;
}

input {
    outline: none;
}

@media (pointer: coarse) {
    .mobile-disable {
        display: none !important;
    }
}

a {
    color: inherit;
    text-decoration: none;
}
a.decoration {
    text-decoration: underline;
    color: $color-primary;
}
a.opacity:hover {
    opacity: 0.7;
}
a[disabled] {
    pointer-events: none;
}

h1,
h2 {
    font-size: 22px;
    color: $color-primary;
    font-weight: 700;
}
h3 {
    font-size: 17px;
    color: $color-primary;
    font-weight: 700;
}
h4 {
    font-size: 17px;
    color: $color-primary;
}

.fa.invisible {
    visibility: hidden;
}
.fa.primary-color {
    color: $color-primary;
}

input[disabled],
select[disabled] {
    opacity: 0.5;
}

.ta-l {
    text-align: left;
}
.ta-c {
    text-align: center;
}
.ta-r {
    text-align: right;
}

// Box sizing
html,
body {
    box-sizing: border-box;
}
*,
*:before,
*:after {
    box-sizing: inherit;
}

// Helpers
.hidden {
    display: none !important;
}
.invisible {
    visibility: hidden !important;
}

// New password block
#new_password_container {
    overflow: hidden;
    height: 0px;
}
// Main page structure
html {
    height: 100%;
    width: 100%;
}
body {
    height: 100%;
    display: flex;
    flex-direction: column;
    font-family: $default-font-family;
    // overflow-x: hidden;
    overflow-x: auto;
    // Noto sans breaks height somehow
    line-height: 1.45;
}
// Disable scroll when navigation is open, only in mobile
@media (max-width: 979px) {
    body.nav-open {
        overflow: hidden;
    }
}
body > header,
body > nav,
body > main,
body > footer {
    flex-shrink: 0;
    width: 100%;
    margin: auto;
}
body > main {
    flex: 1 0 auto;
    position: relative;
}

// Styles
.header {
    height: $header-height;
    padding: 20px 44px;
    background-color: $color-primary;
    box-shadow: 0px 3px 3px $color-shadow;

    &__inner {
        display: flex;
        justify-content: space-between;
    }
    &__section {
        display: flex;
    }
    &__section--pc {
        display: flex;
    }
    &__section--mobile {
        display: none;
    }
    &__link {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        // margin: 0px 6px;
        // padding: 0px 10px;
        padding: 0px 16px;
        border: 1px solid transparent;
        text-align: center;
        font-weight: 600;
        //transition-timing-function: ease-in;
        //transition-property: opacity, background-color, border;
        //transition-duration: 0.2s;
        &__icon {
            margin-right: 5px;
        }
        &__text {
            font-size: 13px;
            color: white;
        }
    }
    &__link--logo {
        padding: 0px;
        border: none;
        &__icon {
            margin-right: 0px;
        }
    }
    // &__link:hover {
    //     background-color: $color-secondary-alpha;
    //     transition-timing-function: ease-out;
    //     transition-duration: 0.25s;
    // }
    &__link--logo:hover {
        background: none;
    }
    &__link--dropdown:hover {
        border-color: white;
        background-color: $color-primary;
    }
    &__link--dropdown:after {
        content: "\25BC\00FE0E";
        color: white;
        font-size: 9px;
        vertical-align: middle;
        margin-left: 0.5em;
    }
    &__link--dropdown:hover:after {
        content: "\25B2\00FE0E";
    }
    &__link--dropdown {
        position: relative;
    }
    &__dropdown {
        list-style: none;
        position: absolute;
        overflow: hidden;
        top: calc(100% + 1px);
        right: -1px;
        width: calc(100% + 2px);
        border: 1px solid $color-primary;
        background-color: white;
        opacity: 0;
        pointer-events: none;
        //transition-timing-function: ease-in;
        //transition-property: opacity;
        //transition-duration: 0.2s;
        z-index: 1;
    }
    &__link--dropdown:hover &__dropdown {
        opacity: 1;
        pointer-events: all;
        //transition-timing-function: ease-out;
        //transition-duration: 0.25s;
    }
    &__dropdown &__link {
        margin: 0px;
        padding: 10px 5px;
        &__text {
            color: $color-primary;
        }
    }
    // &__dropdown &__link:hover {
    //     background-color: $color-secondary;
    // }
    &__nav-toggle {
        // margin-top: 11px;

        &__bar {
            width: 25px;
            height: 3px;
            background-color: #ffffff;
            margin: 5px 0;
            transition: 0.4s;
        }
        .nav-open &__bar--1 {
            transform: translateY(8px) rotate(-45deg);
        }
        .nav-open &__bar--2 {
            opacity: 0;
        }
        .nav-open &__bar--3 {
            transform: translateY(-8px) rotate(45deg);
        }
    }
}

@media (max-width: 979px) {
    .header {
        &__section--pc {
            display: none;
        }
        &__section--mobile {
            display: block;
        }
    }
}

.footer {
    padding: 10px;
    background-color: white;

    &__copyright {
        text-align: center;
    }
}

.nav {
    z-index: 8;
    // flex: 0 0 $nav-width;
    // width: $nav-width;
    border-right: 1px solid $color-secondary;
    color: $color-primary;
    background-color: #ffffff;

    &__inner {
        // margin-left: 20px;
        margin-top: 30px;
    }
    &__group {
        &__header {
            display: flex;
            align-items: center;
            height: 50px;
            padding: 12px 20px;
            border-radius: 10px 0px 0px 10px;
            cursor: pointer;
            font-size: 17px;
            font-weight: 700;
            user-select: none;

            &__icon {
                width: 25px;
                height: 25px;
                margin-right: 10px;
            }
        }
        &__header::after {
            content: "\25B6\00FE0E";
            // margin-left: auto;
            margin-left: 15px;
            font-size: 11px;
            z-index: 11;
        }
        &__header.expanded::after {
            content: "\25BC\00FE0E";
        }
        &__header:hover {
            background-color: $color-secondary;
        }
        &__header--open::after {
            content: "\25BC\00FE0E";
        }
        &--no-dropdown &__header::after {
            content: none;
        }
        &__inner {
            overflow: hidden;
        }
        &__inner.collapsed {
            height: 0px;
        }
    }
    &__group--mobile {
        display: none;
    }
    &__links {
        &__header {
            display: flex;
            align-items: center;
            min-height: 40px;
            padding: 12px 20px;
            padding-left: 56px;
            border-radius: 10px 0px 0px 10px;
            cursor: pointer;
            position: relative;
            font-size: 13px;
            font-weight: 700;

            &__icon {
                position: absolute;
                width: 9px;
                height: 9px;
                left: 40px;
                // top: calc(50% - 9px);
            }
            &__v {
                position: absolute;
                width: 0px;
                height: calc(50% - 5px);
                left: 44px;
                bottom: 0px;
                border-left: thin solid $color-primary;
            }
        }
        &__header:hover {
            background-color: $color-secondary;
        }
        &__list li {
            font-size: 0px;

            a {
                display: block;
            }
        }
    }
    &__link {
        display: flex;
        align-items: center;
        min-height: 40px;
        padding: 12px 20px;
        padding-left: 56px;
        border-radius: 10px 0px 0px 10px;
        cursor: pointer;
        position: relative;
        font-size: 13px;

        &:hover {
            background-color: $color-secondary;
        }

        &__v {
            position: absolute;
            width: 0px;
            height: 100%;
            left: 44px;
            top: 0px;
            border-left: thin solid $color-primary;
        }
        &__h {
            position: absolute;
            width: 9px;
            height: 0px;
            left: 44px;
            border-top: thin solid $color-primary;
        }
        .nav__links__list .nav__links__item:last-child &__v {
            height: 50%;
        }
    }
}

@media (max-width: 979px) {
    .nav {
        position: fixed;
        width: 265px;
        top: $header-height;
        bottom: 0px;
        right: -265px;
        transition-timing-function: ease-in;
        transition-property: transform;
        transition-duration: 0.2s;
        box-shadow: -3px 0px 3px $color-shadow;
        border: none;
        overflow-y: auto;

        .nav-open & {
            transition-timing-function: ease-out;
            transition-duration: 0.25s;
            transform: translateX(-265px);
        }

        &__inner {
            margin: 30px 0px;
        }
        &__group {
            &__header {
                padding-left: 50px;
                border-radius: 0px;
                font-size: 19px;

                &__icon {
                    margin-right: 14px;
                }
            }
            &__header--small {
                font-size: 17px;
            }
            &__header::after {
                margin-left: 12px;
            }
        }
        &__group--mobile {
            display: block;
        }
        &__group--user {
            margin-bottom: 20px;
        }
        &__links {
            padding-left: 25px;

            &__header {
                min-height: 36px;
                padding-left: 54px;
                border-radius: 0px;
                font-size: 17px;

                &__icon {
                    top: calc(50% - 13px);
                }
            }
        }
        &__link {
            min-height: 36px;
            padding-left: 54px;
            border-radius: 0px;
            font-size: 17px;

            &__h {
                width: 9px;
            }
        }
    }
}

.main {
    display: flex;

    &__content__overlay {
        position: fixed;
        top: $header-height;
        bottom: 0px;
        left: 0px;
        right: 0px;
        background-color: $color-overlay-bg;
        display: none;
    }
    .nav-open &__content__overlay {
        display: block;
    }
    &__breadcrumbs {
        padding: 10px 20px;
        flex: 0 0 $breadcrumbs-height;
        background: $color-secondary;
        padding: 15px 20px;
        font-weight: 600;
        color: $color-primary;
        font-size: 13px;

        & .breadcrumb {
            list-style: none;
            display: flex;
            flex-wrap: wrap;

            & .breadcrumb-item {
                margin-right: 0.5em;

                &:not(:first-child)::before {
                    content: "＞";
                    display: inline-block;
                    margin-right: 0.5em;
                }

                & a {
                    font-size: inherit;
                    font-weight: inherit;
                }
            }
        }
    }
    &__content {
        // width: 100%;
        width: auto;
        flex-grow: 1;
        // max-width: calc(100% - #{$nav-width});
        // flex: 1 0 auto;
        // display: flex;
        // flex-direction: column;
        background-image: url("/images/bg.png");

        &--auth,
        &--error {
            max-width: 100%;
            padding: 20px;
        }
    }
    &__content__container {
        padding: $main-content-container-padding;
        flex: 1 0 auto;
        //display: flex;
        //flex-direction: column;
    }
}
@media (max-width: 979px) {
    .main {
        &__content {
            max-width: 100%;
        }
    }
}

.pagination-wrapper {
    display: flex;
    // align-items: center;
    justify-content: center;
    margin-top: 40px;
}
.pagination {
    list-style: none;
    display: inline-flex;

    & li {
        margin: 0px 15px;

        &:first-child,
        &:last-child {
            display: none;
        }
        & span,
        & a {
            font-size: 17px;
            text-decoration: none;
            color: $color-primary;
        }
        &.active span {
            text-decoration: underline;
        }
    }

    &__input {
        display: inline-flex;
        align-items: center;
        //max-width: 60px;
        margin-left: 15px;

        & input,
        & span {
            display: inline;
            outline: none;
            max-width: 32px;
            text-align: center;
        }
        & input {
            border-radius: 10px;
            border: 1px solid $color-primary;
        }
    }
}
@media (max-width: 599px) {
    .pagination {
        & li {
            margin: 0px 5px;

            &:first-child,
            &:last-child {
                display: none;
            }
            & span,
            & a {
                font-size: 12px;
                text-decoration: none;
                color: $color-primary;
            }
            &.active span {
                text-decoration: underline;
            }
        }
        &__input {
            font-size: 12px;
            display: inline-flex;
            align-items: center;
            //max-width: 60px;
            margin-left: 15px;

            & input,
            & span {
                display: inline;
                outline: none;
                max-width: 32px;
                text-align: center;
            }
            & input {
                border-radius: 10px;
                border: 1px solid $color-primary;
            }
        }
    }
}

.search-result-buttons {
    margin-top: 20px;
}
.search-result-count {
    font-weight: 700;
    &-number {
        font-size: 32px;
    }
}

svg {
    & .fg {
        fill: $color-primary;
        stroke: $color-primary;

        &--no-fill {
            fill: none;
        }
        &--no-stroke {
            fill: none;
        }
    }
    & .bg {
        fill: white;
        stroke: white;

        &--no-fill {
            fill: none;
        }
        &--no-stroke {
            fill: none;
        }
    }
    .inverted &.fg {
        fill: white;
        stroke: white;
    }
    .inverted & .bg {
        fill: $color-primary;
        stroke: $color-primary;
    }
}

.button {
    @include button-reset;

    display: inline-block;
    text-align: center;
    text-decoration: none;
    border: solid 1px transparent;
    border-radius: 20px;
    margin: 2px 16px;
    padding: 0.5em 1em;

    background-color: $color-primary;
    color: white;
    font-size: 13px;
    padding: 6px 18px;

    &:active {
        transform: translateY(1px);
        //filter: saturate(150%);
    }
    //&:focus,
    &:hover {
        opacity: 0.8;
    }

    &[disabled] {
        background: gray;
        pointer-events: none;

        &:hover {
            opacity: 1;
        }
    }

    &--secondary {
        background-color: white;
        border-color: $color-primary;
        color: $color-primary;
    }

    &-small {
        font-size: 10px;
        padding: 0px 10px;
        margin: 0px;
        vertical-align: middle;
    }

    &--svg {
        // border-radius: 50px;
        background: none !important;
        border: none !important;
        color: $color-primary !important;
        width: 66px;
        padding: 0px;
        margin: 15px 3px;

        & svg {
            width: 50px;
            height: 50px;
        }
        & span {
            font-size: 11px;
            display: block;
            margin-top: 15px;
        }
    }

    &.not_taxed {
        position: absolute;
        top: 0px;
        right: 0px;
        width: 15px;
        height: 15px;
        margin: 3px;
        padding: 0px;
        font-size: 9px;

        background-color: white;
        border-color: gray;
        color: gray;

        &.active {
            background-color: $color-primary;
            border-color: $color-primary;
            color: white;
        }
    }

    &.highlight_red {
        display: inline-block;
        width: 8px;
        height: 8px;
        margin: 3px 0px 0px 3px;
        padding: 0px;
        font-size: 5px;
        line-height: 5px;
        border-radius: 0px;

        background-color: white;
        border-color: gray;
        color: gray;

        &.active {
            background-color: red;
            border-color: red;
            color: white;
        }
    }

    &.left-align {
        margin-left: 0;
    }
}

.button:focus {
    border: 1px solid $color-primary;
}

@media (max-width: 979px) {
    .button {
        width: 200px;

        &--svg {
            width: 60px;
        }
    }
}

.container {
    flex: 1 0 auto;
    background-color: white;
    border: 1px solid $color-secondary;
    border-radius: 10px;
    box-shadow: 0px 3px 3px $color-shadow;
    padding: $container-padding;
    margin: $container-margin auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    word-break: break-all;
    overflow-x: auto;

    &--full-width {
        max-width: 100%;
    }
    &--full-height {
        min-height: calc(
            100vh -
                (
                    10px + #{$header-height} + #{$breadcrumbs-height} + 2 *
                        (
                            #{$main-content-container-padding} + #{$container-padding}
                        )
                )
        );
    }

    &--messages {
        padding: 0px;
        margin: 0px auto;
        background: none;
        border: none;
        box-shadow: none;

        & .messages-list {
            list-style: none;
        }
    }
    &--auth {
        border-color: transparent;
        background-color: transparent;
        box-shadow: none;
    }
    @media (min-width: 979px) {
        &--auth {
            position: absolute;
            top: 40%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: 600px;
        }
    }
    &__header {
        display: flex;
        margin-bottom: 40px;

        &__block {
            flex: 1;
            display: flex;
            justify-content: center;
        }
        &__block--left {
            justify-content: flex-start;
        }
        &__block--right {
            justify-content: flex-end;
        }

        & .button {
            min-width: 100px;
            height: min-content;
        }

        & .header-buttons {
            display: flex;
        }
    }
    &__content {
        flex: 1 0 auto;
        // overflow-x: auto;
    }
    &__footer {
        display: flex;
        position: relative;
        margin-top: 40px;

        &:empty {
            display: none;
        }

        &__block {
            flex: 1;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            text-align: center;
        }
        &__block--left {
            justify-content: flex-start;
            text-align: left;
        }
        &__block--right {
            justify-content: flex-end;
            text-align: right;
        }

        &__block--export {
            position: absolute;
            right: 0;
        }

        & .footer-buttons {
            display: flex;
            justify-content: center;
        }

        .svg-buttons {
            display: flex;
            justify-content: flex-end;
            align-items: flex-start;
        }
    }
}

@media (max-width: 1024px) {
    .container {
        padding: 40px 20px;

        &__header {
            flex-direction: column;

            &__block {
                justify-content: center;
            }

            &__block--left,
            &__block--right {
                justify-content: center;
            }

            &__block--right {
                margin-top: 10px;
            }
        }
        &__footer {
            flex-direction: column;

            &__block {
                justify-content: center;
            }

            &__block--right {
                margin-top: 45px;
            }

            &__block--export {
                position: static;
            }

            .svg-buttons {
                justify-content: center;
            }
        }
    }
}
@media (max-width: 979px) {
    .container {
        &__header {
            & .header-buttons {
                flex-direction: column;
                align-items: center;
            }
        }
        &__footer {
            & .footer-buttons {
                flex-direction: column;
                align-items: center;
            }
        }
    }
}

.table {
    width: 100%;
    text-align: center;
    border-collapse: collapse;
    text-align: left;

    & thead {
        background: $color-secondary;

        & th {
            border-left: 1px solid #ffffff;
            white-space: nowrap;

            & i {
                margin-left: 3px;
            }
        }
    }
    & th,
    & td {
        padding: 10px;
        font-size: 13px;
    }
    & th {
        color: $color-primary;
        font-weight: bold;
    }
    & td {
        border-bottom: 1px solid $color-table-border;
        border-right: 1px solid $color-table-border;
        position: relative;

        &:last-child {
            border-right: none;
        }
    }

    & .product-color-variant button {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 34px;
        height: 22px;
        margin: 0;
        padding: 1.5px;
        border: 0.5px solid $color-primary;
        border-radius: 0;
        font-size: 8px;
        color: $color-primary;
        background-color: #ffffff;

        svg {
            width: auto;
            height: 10px;
        }
    }

    .table-input {
        border: none;
        outline: none;
        margin: 5px 0px;
        border-radius: 0px;
        border-bottom: 1px dashed $color-primary;
        text-align: inherit;
        background-color: transparent;
        width: 100%;
        box-shadow: none !important;
    }
    .product .table-input {
        background: none;
    }

    .es-list {
        top: 100% !important;
        width: 200px !important;
        width: fit-content !important;
        text-align: left;
    }
    .product .es-list {
        min-width: 250px;
    }
}

.project-table {
    width: 100%;
    text-align: center;
    border-collapse: collapse;
    text-align: left;

    & thead {
        background: rgba(0, 160, 255, 0.1);

        & th {
            // border-left: 1px solid #FFFFFF;
            white-space: nowrap;

            & i {
                margin-left: 3px;
            }
        }
    }
    & th,
    & td {
        padding: 10px;
        font-size: 13px;
    }
    & th {
        color: #323232;
        font-weight: bold;
    }
    & td {
        border-bottom: 0.25px solid rgba(50, 50, 50, 0.2);
        // border-right: 1px solid $color-table-border;
        position: relative;

        &:last-child {
            border-right: none;
        }
    }

    & .product-color-variant button {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 34px;
        height: 22px;
        margin: 0;
        padding: 1.5px;
        border: 0.5px solid #323232;
        border-radius: 0;
        font-size: 8px;
        color: #323232;
        background-color: #ffffff;

        svg {
            width: auto;
            height: 10px;
        }
    }

    .table-input {
        border: none;
        outline: none;
        margin: 5px 0px;
        border-radius: 0px;
        border-bottom: 1px dashed $color-primary;
        text-align: inherit;
        background-color: transparent;
        width: 100%;
        box-shadow: none !important;
    }
    .product .table-input {
        background: none;
    }

    .es-list {
        top: 100% !important;
        width: 200px !important;
        width: fit-content !important;
        text-align: left;
    }
    .product .es-list {
        min-width: 250px;
    }
}

.estimate-tabs,
.bill-tabs,
.estimate-table,
.bill-table {
    .buttons {
        min-width: 10px;
        width: 10px;
        padding: 10px 5px;
    }
    .buttons-container {
        display: flex;
    }
    .buttons-wrapper {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        &:not(:first-child) {
            margin-left: 5px;
        }

        button {
            width: 9px;
            height: 9px;
            padding: 0px;
            line-height: 0px;
            font-size: 8px;
            font-weight: 700;
            border: 1px solid $color-primary;
            background-color: white;
            color: $color-primary;
            margin-bottom: 5px;

            &.no-border {
                border: none;
            }
            &.secondary {
                background-color: $color-primary;
                color: white;
            }
        }
    }
}

.estimate-tabs,
.bill-tabs {
    padding: 0px 1px;
    display: flex;
    overflow: auto;

    .tab {
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px 10px 0px 0px;
        border: 1px solid $color-primary;
        border-bottom: none;
        background-color: $color-primary;
        color: white;
        font-size: 11px;
        padding: 3px 9px;
        user-select: none;

        &:not(:last-child) {
            margin-right: 8px;
        }

        &.active {
            background-color: white;
            color: $color-primary;
        }
        .buttons-wrapper {
            margin-right: 9px;
        }
        &:first-child {
            .buttons-wrapper {
                display: none;
            }
        }
    }
}
.project-tabs {
    padding: 0px 1px;
    display: flex;
    overflow: auto;

    .tab {
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px 10px 0px 0px;
        border: 1px solid #323232;
        border-bottom: none;
        background-color: #323232;
        color: white;
        font-size: 11px;
        padding: 3px 9px;
        user-select: none;

        &:not(:last-child) {
            margin-right: 8px;
        }

        &.active {
            background-color: white;
            color: #323232;
        }
        .buttons-wrapper {
            margin-right: 9px;
        }
        &:first-child {
            .buttons-wrapper {
                display: none;
            }
        }
    }
}
.estimate-table,
.bill-table,
.sale-bill-table {
    border-top: 1px solid $color-primary;

    td {
        //width: 0.1%;
        min-width: 20px;
        max-width: 250px;
        word-break: break-all;

        .buttons {
            min-width: 0px;
        }

        span {
            display: block;
        }
        &.bg-secondary {
            background-color: $color-background-table-secondary;
        }

        &.tax-button {
            position: relative;

            & span {
                margin-right: 15px;
            }
        }
        &.row-modifiers {
            position: relative;

            & .row-modifiers {
                position: absolute;
                top: 0px;
                left: 0px;
            }
        }

        &.highlight-red {
            & span,
            & input {
                color: red;
            }
        }
    }

    .estimate-child-row,
    .bill-child-row,
    .sale-bill-child-row {
        background-color: $color-background-table-secondary;
    }
    .estimate-group-cell,
    .bill-group-cell,
    .sale-bill-group-cell {
        color: $color-primary;
        width: 0px;
        min-width: 0px;
        max-width: 0px;
        padding: 0px;
        cursor: pointer;

        &::after {
            content: "\25BC\00FE0E";
        }

        &.collapsed {
            &::after {
                content: "\25B6\00FE0E";
            }
        }
    }
    .estimate-child-cell,
    .bill-child-cell,
    .sale-bill-child-cell {
        min-width: 0px;
        max-width: 0px;
        padding: 0px;
    }
    .estimate-child-cell::after,
    .bill-child-cell::after,
    .sale-bill-child-cell::after {
        content: "\21B3\00FE0E";
    }
}

.project-table {
    border-top: 1px solid #323232;

    td {
        //width: 0.1%;
        // min-width: 20px;
        // max-width: 250px;
        word-break: break-all;

        .buttons {
            min-width: 0px;
        }

        span {
            display: block;
        }
        &.bg-secondary {
            background-color: $color-background-table-secondary;
        }

        &.tax-button {
            position: relative;

            & span {
                margin-right: 15px;
            }
        }
        &.row-modifiers {
            position: relative;

            & .row-modifiers {
                position: absolute;
                top: 0px;
                left: 0px;
            }
        }

        &.highlight-red {
            & span,
            & input {
                color: red;
            }
        }
    }

    .project-child-row {
        background-color: $color-background-table-secondary;
    }
    .project-group-cell {
        color: #323232;
        width: 0px;
        min-width: 0px;
        max-width: 0px;
        padding: 0px;
        cursor: pointer;

        &::after {
            content: "\25BC\00FE0E";
        }

        &.collapsed {
            &::after {
                content: "\25B6\00FE0E";
            }
        }
    }
    .project-child-cell {
        min-width: 0px;
        max-width: 0px;
        padding: 0px;
    }
    .project-child-cell::after {
        content: "\21B3\00FE0E";
    }
}

.estimate-totals,
.bill-totals {
    border-collapse: collapse;

    td {
        padding: 6px 8px;
        font-size: 11px;
        font-weight: 600;
    }

    .separation {
        padding-left: 30px;
    }

    .quantity {
        font-size: 17px;
    }

    &-border td {
        border-top: 2px solid #707070;
    }

    & tr:nth-child(even) td {
        padding-top: 0;
    }
    & tr:nth-child(odd) td {
        padding-bottom: 0;
    }
    & tr.discount_row td {
        padding: 6px 8px;
    }
}

.modal {
    display: none;
    position: fixed;
    z-index: 9;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);

    &__background {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    &.shown {
        display: block;
    }

    &__popup {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        margin: auto;
        max-width: calc(100% - 20px);
        width: 544px;
    }

    & .popup {
        border-radius: 10px;
        overflow: hidden;

        &__header {
            height: 36px;
            background-color: $color-primary;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;

            & h3 {
                color: white;
                vertical-align: middle;
            }

            &__close {
                background-color: white;
                color: $color-primary;
                font-size: 17px;
                padding: 4px;
                margin: 0px;
                border-radius: 50px;
                width: 16px;
                height: 16px;
                line-height: 0px;
                position: absolute;
                top: 10px;
                right: 15px;
                border: none !important;
            }
        }
        &__content {
            padding: 40px 75px 0;

            .messages-list {
                margin-bottom: 10px;
            }

            .radio-button {
                input {
                    vertical-align: middle;
                }
            }
        }
        &__footer {
            padding: 40px;
            text-align: center;
        }
    }
}

.input-wrapper {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
}

.v-select .vs__search {
    border: none !important;
    box-shadow: none !important;
    font-size: 12px;
    line-height: 1;
    height: 17px;
}

.vs__selected {
    line-height: 1 !important;
    align-items: baseline;
    font-size: 12px;
}
.vs__dropdown-toggle {
    border: 1px solid $color-primary;
    border-radius: 12px;
    max-height: 24px;
}
.vs__clear {
    display: none;
}

.vs__actions::after {
    content: "▼";
    color: #00a0ff;
    font-size: 12px;
}
.vs__open-indicator {
    display: none;
}

.select2-container--default .select2-selection--single {
    border-radius: 15px;
    border-color: $color-primary;

    .select2-selection__arrow b {
        border-color: $color-primary transparent transparent transparent;
    }

    .select2-container--open & {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }
}
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent $color-primary transparent;
}

.projects {
    .form input {
        border-color: #323232;
    }
    .vs__dropdown-toggle {
        border-color: #323232;
    }
}

.project-container {
    font-family: "Yu Gothic", "Noto Sans JP", sans-serif;

    .form.project-form {
        margin-bottom: 32px;
        font-size: 17px;

        .estimate-form-upper,
        .bill-form-upper,
        .project-form-upper {
            // display: flex;
            margin-bottom: 32px;

            &-left,
            &-right {
                flex: 1 1 50%;
            }
        }

        .form-field {
            // display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            margin: 0 0 8px;

            &.half-width {
                width: 45%;
                margin-right: 5%;
            }

            .label-container {
                flex: 0 0 auto;
                margin-right: 9px;
                width: 100px;
                display: flex;

                & label {
                    margin-right: 0px;
                }
            }

            label {
                flex: 0 0 auto;
                width: 100px;
                height: 24px;
                margin-right: 9px;
                border: 1px solid #323232;
                border-radius: 15px;
                text-align: center;
                line-height: 22px;
                // font-size: 13px;
                font-size: 12px;
                color: #323232;

                &.empty {
                    border: none;
                }
            }

            input {
                width: 100%;
            }
            textarea {
                width: 100%;
            }

            &-content {
                // width: auto;
                // line-height: 24px;
            }
            &.half-width .form-field-content {
                width: 100%;
            }
            &.full-width .form-field-content {
                width: 100%;
            }

            .estimate_name,
            .project_name {
                font-weight: 600;
            }

            .summary_sale_total {
                font-size: 22px;
                font-weight: 600;
            }
        }
    }
}

.form {
    font-size: 17px;

    ul {
        list-style: none;
    }

    input {
        padding: 0 15px;
        // padding: 5px 15px;
    }

    .custom-select {
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 15px;
    }

    input,
    select,
    .custom-select {
        min-width: 0;
        height: 24px;
        border: 1px solid $color-primary;
        border-radius: 12px;
        line-height: 1;

        &:focus {
            box-shadow: 0 0 5px $color-input-focus;
        }

        // IE/Edge do not support focus-within, so it has to be
        // on a different rule.
        &:focus-within {
            box-shadow: 0 0 5px $color-input-focus;
        }
    }

    input.es-input {
        background: url("/images/svg/arrowdown.svg") right 8px center / 10px
            no-repeat;
    }

    .custom-checkbox {
        width: 14px;
        height: 14px;
        border: 1px solid $color-primary;
        color: $color-primary;
        vertical-align: middle;

        &:focus-within {
            box-shadow: 0 0 5px $color-input-focus;
        }
    }

    &-field {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 0 0 16px;

        &--table {
            margin: 0px;
        }

        label {
            flex: 0 0 auto;
            width: 120px;
            color: $color-primary;
            font-weight: 600;
        }

        &-select2-input {
            flex: 0 1 275px;
            max-width: 275px;
        }

        &-content-auto {
            width: auto;
        }

        &-content {
            width: 70px;

            &.multiple-inputs {
                flex-grow: 1;
                display: flex;

                & > input,
                & > .input-wrapper {
                    flex: 0 1 auto;
                    width: 60px;
                }

                .separator {
                    color: $color-primary;
                    margin: 0 4px;
                }
            }

            &.full-width {
                flex-grow: 1;
            }
        }
    }
}

.c-projects {
    color: #323232;
    &.container {
        border-color: #e9e9e9;
    }
    h1,
    label {
        color: #323232;
    }
    .button {
        display: block;
        color: white;
        font-size: 12px;
        line-height: 2em;
        padding: 0 20px;
        background-color: #323232;
        border-radius: 20px;
        margin-right: 10px;
    }
    .table thead {
        background-color: rgba(0, 160, 255, 0.1);
        th {
            color: #323232;
        }
    }
    a.decoration {
        color: #323232;
    }
    .form {
        input.es-input {
            background: url("/images/svg/arrowdown-01.svg") right 8px center /
                10px no-repeat;
        }
        input,
        select,
        .custom-select {
            border-color: #323232;
        }
        .custom-checkbox {
            width: 14px;
            height: 14px;
            border: 1px solid #323232;
            color: #323232;
            vertical-align: middle;
        }
        .custom-select::after {
            color: #00a0ff;
        }
    }
    .form.edition-form {
        .form-sections {
            &-secondary {
                padding: 0 30px;
                border-left: 1px solid #e9e9e9;
            }
        }
        .form-checkboxes > label {
            color: #323232;
        }
    }
    .info-content.about-us .info-content-field label {
        background-color: #e5f5ff;
        color: #323232;
    }
}

.form.edition-form {
    .form-field {
        &-content {
            width: 100%;

            input,
            .custom-select {
                max-width: 248px;
                width: 100%;
            }

            &.multiple-inputs {
                flex: 0 1 auto;
                justify-content: space-between;
                max-width: 248px;

                .input-wrapper {
                    flex: 0 1 auto;
                    width: 116px;
                }
            }

            .radio_buttons {
                & input,
                & label,
                & span {
                    // display: inline-block;
                    width: auto;
                }
                & input {
                    vertical-align: middle;
                }
                & input:not(:first-child) {
                    margin-left: 30px;
                }
            }
        }

        &.company-address,
        &.change-password {
            align-items: flex-start;

            .multiple-inputs {
                flex-direction: column;
                max-width: 100%;

                .input-wrapper {
                    width: 100%;
                    margin-bottom: 8px;
                }

                .post-code {
                    display: inline-flex;
                    max-width: 248px;
                }

                .address {
                    max-width: 500px;
                }

                .password-container {
                    width: 100%;
                }

                .password-field {
                    margin-bottom: 5px;
                }

                .password-input {
                    max-width: 248px;
                }
            }
        }

        &.vertical {
            align-items: flex-start;
            flex-direction: column;

            & .form-field-content {
                margin-top: 10px;

                &.multiple-inputs {
                    flex-direction: column;

                    .input-wrapper {
                        flex-grow: 1;
                        max-width: 248px;
                        width: 100%;
                        margin-bottom: 12px;
                    }
                }
            }
        }
    }

    .form-checkboxes {
        & > label {
            font-weight: 600;
            color: $color-primary;
        }

        &-container {
            margin-top: 28px;
        }

        &-item {
            margin-bottom: 15px;
        }
    }

    .button {
        display: blocK;
        margin: 0 auto;
    }

    .form-sections {
        display: flex;

        &-secondary {
            padding: 0 30px;
            border-left: 1px solid #ddedff;
        }

        &-big {
            width: 60%;
        }

        &-small {
            width: 40%;
        }

        &-column {
            flex: 1 1 auto;
            width: 33%;
        }

        &-subsection {
            padding-bottom: 30px;
        }
    }
}

.form.estimate-form,
.form.bill-form,
.form.project-form {
    margin-bottom: 32px;
    font-size: 17px;

    .estimate-form-upper,
    .bill-form-upper,
    .project-form-upper {
        display: flex;
        margin-bottom: 32px;

        &-left,
        &-right {
            flex: 1 1 50%;
        }
    }

    .form-field {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 0 0 8px;

        &.half-width {
            width: 45%;
            margin-right: 5%;
        }

        label {
            flex: 0 0 auto;
            width: 100px;
            height: 24px;
            margin-right: 9px;
            border: 1px solid #000000;
            border-radius: 15px;
            text-align: center;
            line-height: 22px;
            font-size: 13px;
            color: #000000;

            &.empty {
                border: none;
            }
        }

        input {
            width: 100%;
        }
        textarea {
            width: 100%;
        }

        &-content {
            // width: auto;
            // line-height: 24px;
        }
        &.half-width .form-field-content {
            width: 100%;
        }
        &.full-width .form-field-content {
            width: 100%;
        }

        .estimate_name,
        .project_name {
            font-weight: 600;
        }

        .summary_sale_total {
            font-size: 22px;
            font-weight: 600;
        }
    }
}

.bill_projects {
    .info-field-content,
    .form-field-content {
        width: 100%;
        display: flex;
        justify-content: flex-start;
    }
    .bill_project_links {
        a {
            display: block;
            margin-right: 10px;
        }
    }
    .bill_project_buttons {
        margin-left: 10px;

        .button {
            width: 80px;
        }
    }
}

@media (max-width: 979px) {
    .form-field {
        display: block;

        &-content {
            display: block;
        }

        &-content.custom-select {
            display: flex;
        }
    }

    .form.edition-form {
        .form-sections {
            &-big,
            &-small {
                width: 50%;
            }

            // &-secondary {
            //     margin-top: 40px;
            //     padding: 0;
            //     border-left: none;
            // }

            &-column {
                width: 100%;
            }
        }
    }

    .form.estimate-form,
    .form.bill-form,
    .form.project-form {
        .form-field.half-width {
            width: 100%;
            margin-right: 0px;
        }

        .estimate-form-upper,
        .bill-form-upper,
        .project-form-upper {
            display: block;
            margin-bottom: 0;
        }
    }
}

@media (max-width: 599px) {
    .form.edition-form {
        .form-sections {
            flex-direction: column;

            &-big,
            &-small {
                width: 100%;
            }

            &-secondary {
                margin-top: 40px;
                padding: 0;
                border-left: none;
            }

            &-column {
                width: 100%;
            }
        }
    }
}

.users-create .form.edition-form {
    .input-wrapper {
        display: flex;
    }

    .form-field {
        display: block;

        &-content {
            margin-top: 12px;
        }
    }
}

.info-content {
    display: flex;
    padding: 20px 0;
    border-radius: 10px;
    background-color: #f2f8ff;
    font-size: 17px;

    ul {
        list-style: none;
    }

    &-column {
        flex-grow: 1;
        padding: 30px 40px;
        border-right: 1px solid #d8e3f2;

        &:last-child {
            border-right: none;
        }
    }

    &-field {
        margin-bottom: 25px;

        label {
            color: $color-primary;
            font-weight: 600;
        }

        .child-label {
            color: $color-primary;
            font-size: 13px;
            font-weight: 600;
        }

        &-content {
            margin-top: 5px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &-list li {
        &::before {
            content: "";
            display: block;
            height: 0;
            border-top: 1px solid #d8e3f2;
            margin: 30px 0;
        }

        &:first-child::before {
            display: none;
        }
    }

    &.about-us {
        display: block;
        padding: 0;
        background-color: inherit;

        .info-content-field {
            margin: 0;

            label {
                display: block;
                padding: 7px 21px;
                line-height: 1;
                background-color: #ddedff;
            }

            &-content {
                margin: 0;
                padding: 13px 21px;

                .post-code {
                    margin-right: 10px;
                }
            }
        }
    }
}

.info-table {
    &-heading {
        margin-top: 30px;

        h2 {
            font-size: 17px;
        }
    }

    &-content {
        margin-top: 13px;
    }
}

.estimate-info,
.bill-info,
.project-info {
    margin-bottom: 32px;
    font-size: 17px;

    &-upper {
        display: flex;
        margin-bottom: 32px;

        &-left,
        &-right {
            flex: 1 1 50%;
        }
    }

    .info-field {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 0 0 8px;

        label {
            flex: 0 0 auto;
            width: 100px;
            height: 24px;
            margin-right: 9px;
            border: 1px solid #000000;
            border-radius: 15px;
            text-align: center;
            line-height: 22px;
            font-size: 13px;

            &.empty {
                border: none;
            }
        }

        &-content {
            line-height: 24px;
        }

        .estimate_name,
        .project_name {
            font-weight: 600;
        }

        .summary_sale_total {
            font-size: 22px;
            font-weight: 600;
        }
    }
}

@media (max-width: 979px) {
    .info-content {
        flex-direction: column;
        padding: 40px 20px;

        &-column {
            padding: 0 20px;
            border-right: none;
        }
    }

    .estimate-info,
    .bill-info,
    .project-info {
        &-upper {
            display: block;
            margin-bottom: 0;
        }
    }
}

.form--auth {
    & h1 {
        margin-bottom: 20px;
    }
    & .form-field {
        flex-direction: column;
        align-items: flex-start;
        margin-top: 10px;
    }
    & label {
        width: 100%;
        color: $color-primary;
        font-weight: 700;
    }

    & .input-wrapper {
        position: relative;
        width: 100%;
    }
    & input {
        width: 100%;
        margin-top: 15px;
        font-size: 17px;
        height: 50px;
        border-radius: 50px;
        box-shadow: 0px 0px 10px $color-primary-alpha;
    }
    & .auth-forgot-password {
        margin-top: 5px;
        font-size: 13px;
        text-decoration: underline;
        color: $color-primary;
    }
    & .button--submit {
        width: 100%;
        margin: 0px;
        margin-top: 5px;
        font-size: 17px;
        height: 50px;
        border-radius: 50px;
        line-height: 35px;
    }
    .login-password-wrapper {
        width: 100%;
        margin-top: 15px;
        border-radius: 50px;
        box-shadow: 0px 0px 10px $color-primary-alpha;
        padding: 0px;
        border: 1px solid $color-primary;
        display: flex;
        background-color: white;
    }
    .login-password {
        border: none;
        box-shadow: none;
        outline: none;
        margin: 0px;
        border-radius: 50px 0px 00px 50px;
    }
    .toggle-show-password {
        width: 50px;
        height: 50px;
        //display: flex;
        //align-content: center;
        //justify-content: center;
        margin: 0px;
        padding: 0px;
        background: none;
        border: none;
        border-radius: 0px 50px 50px 0px;
        outline: none;
        color: gray;
        &:hover {
            color: $color-primary;
        }
    }
    .message {
        width: 100%;
    }
}

.news-list {
    border-collapse: collapse;
    width: 100%;
}
.news-item {
    &__info {
        padding-top: 20px;
        padding-bottom: 20px;
        vertical-align: baseline;
        border-bottom: 1px solid $color-table-border;

        &--category {
            width: 1%;
            white-space: nowrap;
            padding-right: 10px;

            & span {
                display: inline-block;
                width: 100%;
                border-radius: 10px;
                border: 1px solid $color-primary;
                font-size: 9px;
                color: $color-primary;
                text-align: center;
                min-width: 90px;
                padding: 5px 15px;

                &:hover {
                    opacity: 0.8;
                }
            }
        }
        &--date {
            width: 1%;
            white-space: nowrap;
            padding-right: 15px;

            & span {
                display: inline-block;
                font-size: 11px;
                text-align: center;
                min-width: 60px;

                .news_article & {
                    font-size: 13px;
                }
            }
        }
        &--title {
            & span {
                display: inline-block;
                font-size: 13px;
                text-align: left;

                .news_article & {
                    font-size: 17px;
                    font-weight: 700;
                }
            }
            & a span {
                text-decoration: underline;
                color: $color-primary;

                &:hover {
                    opacity: 0.8;
                }
            }
        }
    }

    &:last-child &__info {
        border-bottom: none;
    }
}

// Specific styles for products colors table
.product_variants {
    & tbody {
        & tr:not(:first-child) {
            & .product_color_delete,
            & .product_color_name {
                display: none;
            }
        }
    }
}
.product_add_product_color {
    width: 100%;
    margin: 5px 0px;
}

// Specific styles for customers
.container__content--customers {
    .form-field label {
        width: 100%;
    }
    @media (max-width: 979px) {
        .form-sections {
            max-width: 300px;
            margin: auto;

            .form-field-content.multiple-inputs,
            .form-field-content.multiple-inputs .input-wrapper,
            .form-field-content input,
            .form-field-content .custom-select,
            .input-wrapper {
                max-width: 100% !important;
            }
        }
    }
}

.message {
    position: relative;
    border-radius: 10px;
    padding: 5px 10px;
    margin-top: 5px;
    font-size: 13px;
    list-style: none;

    &--info {
        border: 1px solid $color-info;
        background: $color-info-background;
        color: $color-info;
    }
    &--error {
        border: 1px solid $color-danger;
        background: $color-danger-background;
        color: $color-danger;
    }

    &--input {
        margin-top: 15px;
        font-size: 17px;

        .project-form & {
            white-space: pre-line;
        }

        &:after,
        &:before {
            bottom: calc(100% - 1px);
            left: 25px;
            border: solid transparent;
            content: "";
            height: 0;
            width: 0;
            position: absolute;
            transform: translateY(-1px);
        }

        &:after {
            border-color: transparent;
            border-bottom-color: $color-danger-background;
            border-width: 10px;
            margin-left: -10px;
            transform: translateY(1px);
        }
        &:before {
            border-color: transparent;
            border-bottom-color: inherit;
            border-width: 10px;
            margin-left: -10px;
        }
    }
    &--absolute {
        position: absolute;
        top: 100%;
        word-break: keep-all;
        white-space: nowrap;
    }
}

.custom-select {
    display: inline-flex;
    align-items: center;
    position: relative;
    padding: 5px 7px;
    border: 1px solid gray;
    box-sizing: border-box;
    font-size: inherit;
    overflow: hidden;
    white-space: nowrap;

    span {
        flex-grow: 1;
    }

    select {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 0;
        border: none;
        opacity: 0;
    }

    &::after {
        margin-left: 10px;
        color: $color-primary;
        font-size: 11px;
        content: "\25BC\00FE0E";
    }
}

.custom-checkbox {
    display: inline-block;
    position: relative;
    width: 18px;
    height: 18px;
    border: 1px solid gray;
    box-sizing: border-box;
    overflow: hidden;
    vertical-align: bottom;

    svg {
        display: block;
        width: 100%;
        height: 100%;
        margin-top: 1px;
        margin-left: 1px;

        path {
            opacity: 0;
        }

        &.checked path {
            opacity: 1;
        }
    }

    input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
    }
}

input[type="radio"] {
    display: none;
}

.projects_radio {
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    position: relative;
    width: auto;
    padding-left: 25px;
}
.projects_radio::before {
    background: #fff;
    border: 1px solid #231815;
    border-radius: 50%;
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    height: 16px;
    width: 16px;
}
.projects_radio::after {
    background: #00a0ff;
    border-radius: 50%;
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 8px;
    transform: translateY(-50%);
    opacity: 0;
    height: 10px;
    width: 10px;
}
input[type="radio"]:checked + .projects_radio::brfore {
    border-color: #00a0ff;
}
input[type="radio"]:checked + .projects_radio::after {
    opacity: 1;
}

.estimates_radio {
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    position: relative;
    width: auto;
    padding-left: 25px;
}
.estimates_radio::before {
    background: #fff;
    border: 1px solid #231815;
    border-radius: 50%;
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 0px;
    transform: translateY(-50%);
    height: 16px;
    width: 16px;
}
.estimates_radio::after {
    background: #1c3c92;
    border-radius: 50%;
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 3px;
    transform: translateY(-50%);
    opacity: 0;
    height: 10px;
    width: 10px;
}
input[type="radio"]:checked + .estimates_radio::brfore {
    border-color: #1c3c92;
}
input[type="radio"]:checked + .estimates_radio::after {
    opacity: 1;
}

.print-button {
    &.disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
}
