@mixin button-reset {
    padding: 0;
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
    cursor: pointer;
    outline: none;
    user-select: none;
}
