//
$nav-width: 220px;
$header-height: 80px;
$breadcrumbs-height: 40px;
$main-content-container-padding: 20px;
$container-padding: 40px;
$container-margin: 20px;


// Colors
$color-primary: #003E92;
$color-primary-alpha: rgba(0, 62, 146, 0.3);
$color-secondary: #DDEDFF;
$color-secondary-alpha: rgb(221, 237, 255, 0.3);
$color-shadow: rgba(0, 61, 146, 0.20);
$color-overlay-bg: rgba(112, 112, 112, 0.3);
$color-table-border: #dcdcdc;
$color-info: #003E92;
$color-info-background: #DDEDFF;
$color-danger: #62090d;
$color-danger-background: #fcd9d5;
$color-input-focus: #80B6FF;
$color-background-table-secondary: #F2F8FF;

// Typography
$default-font-family: "Noto Sans JP", sans-serif;
//$default-font-family: YuGothic, 'Yu Gothic', sans-serif;
